<template>
	<div class="main">
		<div class="EnterpriseHonor container-secondary-main">
			<h6 class="enteringHuifa-titile">{{lang.index_huifa_honor}}</h6>
			<div class="EnterpriseHonor-list">
				<div class="type" v-for="honor in honor_list.data">
					<div class="type-image">
						<img v-if="honor.img" :src="honor.img" alt="" />
						<img v-else src="@/assets/no_img.png" alt="" />
						<div class="detail" @click="path('/enteringHuifa/detail/'+honor.id+'/EnterpriseHonor')">
							<p>{{lang.project_view}}</p>
						</div>
					</div>
					<p class=" line-one">{{honor.title}}</p>
				</div>
				
			</div>
			<div v-if="honor_list.data.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
				<img class="type-image" src="@/assets/nodata.gif" alt=""/>
				<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
			</div>
			<div class="pagination" v-if="honor_list.data.length >0">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:layout="layout"
				:current-page.sync="current.page" :page-size="current.per_page"   :pager-count="5"  :total="honor_list.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getHuifaHonor } from "@/api/index";
	import {
		mapState
	} from 'vuex'
	export default {
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			}),
			layout() {
			  return this.isMobile ? 'prev, pager, next' : 'prev, pager, next, jumper'; // 根据isMobile的值动态设置layout属性值。如果isMobile为true，则不显示跳转输入框。
			}
		},
		data() {
			return {
				// 分页
				current: {
				  page: 1,
				  per_page: 6
				},
				honor_list:[],
				isMobile: false
			}
		},
		created() {
			this.getHuifaHonor()
		},
		mounted() {
		    window.screenWidth = document.body.clientWidth;
			if(window.screenWidth<=768){
				this.isMobile=true
			}else{
				this.isMobile=false
			}
		},
		methods: {
			handleSizeChange(pageSize){
				 this.current.per_page = pageSize;
				 this.getHuifaHonor();
			},
			handleCurrentChange(pageSize){
				 this.current.page = pageSize;
				 this.getHuifaHonor();
			},
			getHuifaHonor(){
				let data = Object.assign(
				  this.current
				); 
				getHuifaHonor(data).then(res=>{
					this.honor_list=res.data.honor_list
				})
			},
			path(routePath) {
				this.$router.push(routePath); 
				
			}
		}

	}
</script>

<style lang='less'>
	.EnterpriseHonor {
		background-color: #fff;
		padding: 0.1rem 2.4375rem;
		margin-top: 1rem;
		border-radius: 8px 0px 0px 8px;

		.EnterpriseHonor-list {
			width: 100%;
			margin: 2rem 0;
			display: flex;
			flex-wrap: wrap;

			.type {
				width: 21.625rem;
				height: 17.625rem;
				margin-right: 2.5rem;
				margin-bottom: 2.06rem;
				text-align: center;

				.type-image {
					background-image: url('../../assets/enteringHuifa/EnterpriseHonor_bj_1.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					text-align: center;
					width: 100%;
					height: 15.625rem;
					margin-bottom: 0.825rem;
					padding: 1rem 1.3085rem;
					position: relative;

					img {
						width: 100%;
						height: 100%;
					}

					.detail {
						background-color: rgba(51, 51, 51, 0.5);
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						position: absolute;
						display: none;

						p {
							width: 5.4375rem;
							height: 2.625rem;
							background: #D70C19;
							box-shadow: 0px 0px 7px 0px #530A0A;
							border-radius: 2px 2px 2px 2px;
							font-size: 0.875rem;
							color: #FEFEFE;
							line-height: 2.625rem;
							margin: auto;
							margin-top: 30%;
						}
					}
				}

			}

			.type:nth-child(3n) {
				margin-right: 0;
			}

			.type-image:hover .detail {
				display: block;
			}
		}

		.pagination {
			width: 100%;
			margin-bottom: 2rem;
			flex-direction: row-reverse
		}
	}
</style>