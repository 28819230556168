<template>
	<div class="main">
		<div class="CorporateCulture container-secondary-main" >
			<h6 class="enteringHuifa-titile">{{lang.index_huifa_culture}}</h6>
			<div class="CorporateCulture-one container-main">
				<div v-for="bann1 in banner1"><img :src="bann1.img" alt="" /></div>
			</div>
			<div class="CorporateCulture-two container-main" ref="myDiv">
				<div v-for="bann2 in banner2"><img :src="bann2.img" alt="" /></div>
			</div>
			<div class="header-null" style="clear: both;"></div>
		</div>
	</div>
</template>

<script>
	import { getHuifaCulture } from "@/api/index";
	import {
		mapState
	} from 'vuex'
export default {
	computed: {
		...mapState({
		  lang: state => state.user.lang,
		})
	},
    data() {
        return {
			banner1:[],
			banner2:[],
            loading:false,
					  
        }
    },
    created() {
    	this.getHuifaCulture()
		
    },
	methods:{
		getHuifaCulture(){
			getHuifaCulture().then(res=>{
				this.banner1 = res.data.banner1
				this.banner2 = res.data.banner2
			})
		},
	}
}
</script>

<style lang='less'>
	.CorporateCulture{
		
		.CorporateCulture-one{
			div{
				float: left;
				width: 16rem;
				margin-right: 2rem;
				img{
					width: 100%;
				}
			}
			div:nth-child(4n){
				margin-right: 0;
			}
		}
		.CorporateCulture-two{
			div{
				float: left;
				width: 34.0625rem;
				margin-right: 1.9375rem;
				margin-top: 1.5rem;
				img{
					width: 100%;
				}
			}
			div:nth-child(2n){
				margin-right: 0;
			}
		}
		
	}

</style>