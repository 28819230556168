<template>
	<div class="main">
		<div class="MajorEvents container-secondary-main">
			<h6 class="enteringHuifa-titile">{{lang.index_huifa_big_news}}</h6>
			
			<div v-loading="loading" :element-loading-text="lang.huifa_loading" element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.2)" class="h-100 p-r">
				<el-timeline>
				    <el-timeline-item  :timestamp="item.name" placement="top" v-for="(item, index) in huifa_memorabilia" :key="index">
				      <el-card>
				        <p v-for="(content, index) in item.list">{{content.title}}</p>
				      </el-card>
				    </el-timeline-item>
				    
				  </el-timeline>
				<!-- <div class="timelineBox" ref="myDiv">
					<el-timeline>
						<el-timeline-item v-for="(item, index) in huifa_memorabilia" :key="index"
							color="#DADADA">
							<div v-if="index%2==0" class="evenDiv">
								<div class="timelinItemBox0 other">
									<div class="timelinItemBox-top f-r a-c" >
										<div>{{item.name}}</div>
									</div>
									<div class="timelinItemBox-bottom">
										<p v-for="(content, index) in item.list">{{content.title}}</p>
									</div>
								</div>
								<div class="timelinItemBox1">
									<div class="timelinItemBox-top f-r a-c">
										
									</div>
									<div class="timelinItemBox-bottom">
										
									</div>
								</div>
							</div>

							<div v-else class="unevenDiv">
								<div class="timelinItemBox0">
									<div class="timelinItemBox-top f-r a-c">
										
									</div>
									<div class="timelinItemBox-bottom">
										
									</div>
								</div>
								<div class="timelinItemBox1">
									<div class="timelinItemBox-top f-r a-c" >
										<div>{{item.name}}</div>
									</div>
									<div class="timelinItemBox-bottom">
										<p v-for="(list, index) in item.list">{{list.title}}</p>
									</div>
								</div>
							</div>
						</el-timeline-item>
					</el-timeline>
				</div> -->
			</div>
			<div v-if="huifa_memorabilia.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
				<img class="type-image" src="@/assets/nodata.gif" alt=""/>
				<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
			</div>
			
			<div class="header-null" style="clear: both;"></div>
		</div>
	</div>
</template>

<script>
	import { getHuifaMemorabilia } from "@/api/index";
	import {
		mapState
	} from 'vuex'
export default {
	computed: {
		...mapState({
		  lang: state => state.user.lang,
		})
	},
    data() {
        return {
			huifa_memorabilia:[],
            loading:false,
					  
        }
    },
    created() {
    	this.getHuifaMemorabilia()
		
    },
	methods:{
		getHuifaMemorabilia(){
			getHuifaMemorabilia().then(res=>{
				this.huifa_memorabilia = res.data.huifa_memorabilia
			})
		},
	}
}
</script>

<style lang='less'>
	.MajorEvents{
		/* min-height: 1400px; */
		.el-timeline-item__timestamp.is-top {
		    margin-bottom: 8px;
		    padding-top: 4px;
		    color: #D70C19;
		    font-weight: bold;
		    font-size: 1.25rem;
		}
		.el-card.is-always-shadow {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          box-shadow: none;
        }
		
		
	}

</style>