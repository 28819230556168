<template>
	<div class="main">
		
		<div class="PublicCharity container-secondary-main">
			<h6 class="enteringHuifa-titile">{{lang.index_huifa_welfare}}</h6>
			<searchLine @update-data="handleData"></searchLine>
			<div class="PublicCharity-list">
				<div class="PublicCharity-list-li" v-for="article in article_list.data" @click="path('/enteringHuifa/detail/'+article.id+'/PublicCharity')">
					<div class="info-img">
						<img class="info-img-y" v-if="article.img" :src="article.img" alt="" />
						<img v-else src="@/assets/no_img.png" alt="" />
					</div>
					<div class="info-c">
						<h6 class="line-one">{{article.title}}</h6>
						<p class="content line-four">{{article.description}}</p>
						<p class="more"><img src="@/assets/index/more_white.png"/>{{lang.project_view}}</p>
						<p class="date">{{article.add_time}}<!-- &nbsp;&nbsp;&nbsp;&nbsp;润农采购部<i class="el-icon-view"></i><span>11111</span> --></p>
					</div>
				</div>
			</div>
			<div v-if="article_list.data.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
				<img class="type-image" src="@/assets/nodata.gif" alt=""/>
				<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
			</div>
			<div class="pagination" v-if="article_list.data.length > 0">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:layout="layout"
				:current-page.sync="current.page" :page-size="current.per_page" :pager-count="5"  :total="article_list.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import searchLine from '../../components/searchLine.vue';
	import { getArticleList } from "@/api/index";
	import {
		mapState
	} from 'vuex'
export default {
	components:{
		searchLine
	},
	computed: {
		...mapState({
		  lang: state => state.user.lang,
		}),
		layout() {
		  return this.isMobile ? 'prev, pager, next' : 'prev, pager, next, jumper'; // 根据isMobile的值动态设置layout属性值。如果isMobile为true，则不显示跳转输入框。
		}
	},
    data() {
        return {
			// 分页
			current: {
			  page: 1,
			  per_page: 6
			},
			formData:{
				type_id:1,
				keywords:''
			},
			article_list:[],
			isMobile: false	  
        }
    },
	created() {
		this.getArticleList();
	},
	mounted() {
	    window.screenWidth = document.body.clientWidth;
		if(window.screenWidth<=768){
			this.isMobile=true
		}else{
			this.isMobile=false
		}
	},
	methods:{
		handleSizeChange(pageSize){
			 this.current.per_page = pageSize;
			 this.getArticleList();
		},
		handleCurrentChange(pageSize){
			 this.current.page = pageSize;
			 this.getArticleList();
		},
		getArticleList(){
			let data = Object.assign(
			  this.current,
			  this.formData
			); 
			getArticleList(data).then(res=>{
				this.article_list=res.data.article_list
			})
		},
		handleData(data) {
		      this.formData.keywords = data;
			  this.current.page=1
			  this.getArticleList();
		},
		path(routePath) {
			this.$router.push(routePath); 
			
		}
	}
    
}
</script>

<style lang='less'>
	.PublicCharity{
		background-color: #fff;
		padding: 0.1rem 2.4375rem;
		margin-top: 1rem;
		border-radius: 8px 0px 0px 8px;
		.searchLine{
			background-color: #fff;
			.sort .search-PublicCharity .el-input__inner {
			    background: #fff;
			}
		}
		.PublicCharity-list{
			.PublicCharity-list-li{
				height: 14.625rem;
				padding-top: 2.5rem;
				border-top: 1px solid #EEEFF0;
				margin-bottom: 2.5rem;
				overflow: hidden;
				.info-img{
					float: left;
					width: 24.125rem;
					height: auto;
					text-align: center;
					.info-img-y{
						width: 100%;
					}
					img{
						max-width: 100%;
					}
				}
				.info-c{
					float: right;
					width: 44.125rem;
					height: 100%;
					position: relative;
					h6{
						font-weight: bold;
					}
					.content{
						font-size: 0.875rem;
						color: #666666;
						line-height: 1.5rem;
						width: 100% !important;
					}
					.more{
						width: 6.25rem;
						height: 2.25rem;
						background: #D70C19;
						border-radius: 1.125rem;
						color:#ffffff;
						line-height: 2.25rem;
						font-size: 0.875rem;
						position: absolute;
						bottom: 0%;
						left: 0;
						margin-bottom: 0;
						img{
							width: 1.25rem;
							height: 1.25rem;
							margin:0.5rem 0.65rem 0 1.125rem;
							float: left;
						}
					}
					.date{
						color: #999999;
						font-size: 0.875rem;
						position: absolute;
						bottom: 0%;
						right: 0;
						margin-bottom: 0;
						i{
							margin-left: 1rem;
						}
					}
				}
			}
		}
		.pagination {
			width: 100%;
			margin-bottom: 2rem;
			flex-direction: row-reverse
		}
	}

</style>