<template>
	<div class="main">
		<div class="secondary-banner">
			<img :src="img_list[0].img" alt="" style="width: 100%;">
		</div>
		<div class="enteringHuifa-tab container-secondary-main">
			<div @click="path('/enteringHuifa/index/'+(index+1))"
				:class="type==(index+1) ? 'tab-li  active' : 'tab-li '" v-for="(item, index) in typeList">
				<p class="cursor"><img :src="item.imgUrl" />{{item.name}}</p>
			</div>
		</div>
		<div class="enteringHuifa-content ">
			<Introduction v-if="type==1"></Introduction>
			<Major-Events v-if="type==2"></Major-Events>
			<Corporate-Culture v-if="type==3"></Corporate-Culture>
			<Enterprise-Honor v-if="type==4"></Enterprise-Honor>
			<Public-Charity v-if="type==5"></Public-Charity>
			<Internal-Publications v-if="type==6"></Internal-Publications>
		</div>
	</div>
</template>

<script>
	import Introduction from '../../components/enteringHuifaMain/Introduction.vue';
	import MajorEvents from '../../components/enteringHuifaMain/MajorEvents.vue';
	import CorporateCulture from '../../components/enteringHuifaMain/CorporateCulture.vue';
	import EnterpriseHonor from '../../components/enteringHuifaMain/EnterpriseHonor.vue';
	import PublicCharity from '../../components/enteringHuifaMain/PublicCharity.vue';
	import InternalPublications from '../../components/enteringHuifaMain/InternalPublications.vue';
	import {
		getHuifaTopImg
	} from "@/api/index";
	import {
		getAdList
	} from '@/api/store.js'
	import {
		bannerType
	} from "@/utils/type.js"
	import {
		mapState
	} from 'vuex'
	export default {
		name: "enteringHuifa",
		components: {
			Introduction,
			MajorEvents,
			CorporateCulture,
			EnterpriseHonor,
			PublicCharity,
			InternalPublications
		},
		computed: {
			...mapState({
				lang: state => state.user.lang,
			})
		},
		data() {
			return {
				bannerId: bannerType.inHuiFa,
				img_list: [{
					img: ''
				}],
				type: 1,
				typeList: [{
					imgUrl: require("../../assets/enteringHuifa/enteringHuifa_icon_1.png"),
					name: '惠发简介'
				}, {
					imgUrl: require("../../assets/enteringHuifa/enteringHuifa_icon_2.png"),
					name: '惠发大事记'
				}, {
					imgUrl: require("../../assets/enteringHuifa/enteringHuifa_icon_3.png"),
					name: '企业文化'
				}, {
					imgUrl: require("../../assets/enteringHuifa/enteringHuifa_icon_4.png"),
					name: '企业荣誉'
				}, {
					imgUrl: require("../../assets/enteringHuifa/enteringHuifa_icon_5.png"),
					name: '公益事业'
				}, {
					imgUrl: require("../../assets/enteringHuifa/enteringHuifa_icon_6.png"),
					name: '企业内刊'
				}]
			}
		},
		watch: {
			'$route'(nval) {
				this.type = nval.params.id
			}
		},
		props: ['id'], // 将路由中传递的参数id解耦到组件的props属性上
		mounted() {
			this.type = this.id
		},
		created() {
			this.getAdListFun()
			this.typeList.map((item, index) => {
				if (index == 0) {
					item.name = this.lang.index_huifa_intro
				} else if (index == 1) {
					item.name = this.lang.index_huifa_big_news
				} else if (index == 2) {
					item.name = this.lang.index_huifa_culture
				} else if (index == 3) {
					item.name = this.lang.index_huifa_honor
				} else if (index == 4) {
					item.name = this.lang.index_huifa_welfare
				} else if (index == 5) {
					item.name = this.lang.index_huifa_publication
				}
			})
		},
		methods: {
			getAdListFun() {
				// 获取广告位图片
				let data = {
					cate_id: this.bannerId
				}
				getAdList(data).then(res => {
					if (res.data.img_list) {
						this.img_list = res.data.img_list
					}

				})
			},
			path(routePath) {
				this.$router.push(routePath); 
			}
		}




	}
</script>

<style lang="less">
	.main {
		background-color: #f2f2f2;

		.enteringHuifa-tab {
			width: 100%;
			height: 7.5rem;
			margin-top: -4rem;
			background: #FFFFFF;
			box-shadow: 0px 0px 21px 0px #cecece;
			border-radius: 8px 8px 8px 8px;
			position: relative;
			z-index: 1;
			padding: 1.75rem 2.4375rem;

			.tab-li {
				float: left;
				padding: 0.8125rem 1.375rem;
				margin-right: 2.5rem;
				border: 1px solid #E9E9E9;
				font-size: 1rem;
				color: #333333;

				p {
					margin-bottom: 0;

					img {
						width: 2.125rem;
						height: 2.125rem;
						margin-right: 0.4375rem;
					}
				}
			}

			.tab-li.active {
				background: #FFE7E8;
				border: 1px solid #FFE7E8;

				p {
					color: #D70C19;
					font-weight: bold;
				}
			}

			.tab-li:last-child {
				margin-right: 0;
			}
		}

		.enteringHuifa-content {
			.enteringHuifa-titile {
				border-left: 0.5625rem solid #D70C19;
				margin-top: 2.5rem;
				padding-left: 0.8125rem;
				font-weight: bold;
				font-size: 1rem;
				margin-bottom: 2.6875rem;
				color: #333333;
			}
		}
	}

	@media (max-width: 1220px) {
		.main .enteringHuifa-tab .tab-li {
			padding: 0.8125rem 0;
			width: 14%;
			text-align: center;
			margin-right: 3%;
			font-size: 0.75rem;
		}

		.Introduction .advantage .advantage-info {
			width: 27%;
			margin-right: 9.5%;
		}

		.CorporateCulture .CorporateCulture-one div {
			width: 22.5%;
			margin-right: 3.33%;
		}

		.CorporateCulture .CorporateCulture-two div {
			width: 48.6%;
			margin-right: 2.8%;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type {
			width: 31%;
			margin-right: 3.5%;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li .info-img {
			width: 35%;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li .info-c {
			width: 63%;
		}

	}

	@media (max-width: 1024px) {
		.main .enteringHuifa-tab {
			padding: 1.75rem 0.4375rem;
		}

		.main .enteringHuifa-tab .tab-li {
			width: 15.8%;
			margin-right: 1%;
		}

		.Introduction .blurb .blurb-info .info-c {
			background: #fff;
			padding: 1rem;
			border-radius: 8px;
			margin-left: 0;
			margin-right: 0px;
			float: right;
		}

		.Introduction .blurb .blurb-info {
			width: 83%;
			right: 20px;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type {
			height: 15.625rem;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type .type-image {
			height: 12.625rem;
		}
	}

	@media (max-width: 853px) {
		/* .CorporateCulture {
			min-height: 71.75rem;
		} */

		.EnterpriseHonor .EnterpriseHonor-list .type {
			height: 13.625rem;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type .type-image {
			height: 10.625rem;
		}
	}

	@media (max-width: 768px) {
		/* .main .enteringHuifa-tab {
			height: 6.8rem;
			margin-top: 0rem;
			padding: 1rem 0.4375rem;
		} */
		.main .enteringHuifa-tab {
		    height: auto;
		    margin-top: 0;
		    padding: 1rem .4375rem;
		    margin: auto;
		    margin-top: -4rem;
		    display: flex !important;
		    justify-content: center;
		    flex-flow: wrap;
		}    

		.main .enteringHuifa-tab .tab-li p img {
			display: block;
			margin: auto;
		}

		.main .enteringHuifa-tab .tab-li,
		.main .enteringHuifa-tab .tab-li.active {
			border: none;
			margin-right: 0 !important;
			width: 33%;
		}
		.Introduction .blurb .blurb-img {
		    width: 100%;
		}

		.Introduction .blurb .blurb-info {
			width: 100%;
			right: 0;
		}

		.Introduction .blurb .blurb-info .title-E {
			margin-top: 1rem;
		}

		.Introduction .blurb .blurb-info .info-c {
			background: rgba(255, 255, 255, 0.9);
			margin-left: 1.5%;
			float: none;
			width: 97%;
			height: 12.75rem;
		}

		.Introduction .blurb .blurb-info .blurb-info-type {
			width: 97%;
			height: 5.25rem;
			margin-left: 1.5%;
			bottom: 1rem;
		}

		.Introduction .blurb .blurb-info .blurb-info-type span {
			font-size: 2rem;
		}

		.Introduction .advantage .advantage-info {
			width: 47.5%;
			margin-right: 5%;
		}

		.Introduction .advantage .advantage-info:nth-child(3n) {
			margin-right: 5%;
		}

		.Introduction .advantage .advantage-info:nth-child(2n) {
			margin-right: 0;
		}

		.Introduction .advantage .advantage-type h6 {
			font-size: 1rem;
			margin-left: 1.5rem;
		}

		.Introduction .advantage .advantage-title .title-E,
		.Introduction .blurb .blurb-info .title-E,
		.Introduction .target .target-title .title-E,
		.Introduction .target .target-title .title-C,
		.Introduction .blurb .blurb-info .title-C,
		.Introduction .advantage .advantage-title .title-C {
			font-size: 1.125rem;
		}

		.Introduction .target .target-title .title-E {
			margin-left: 40%;
		}

		.Introduction .target .target-title .title-C {
			margin-left: 31%;
		}

		.Introduction .advantage .advantage-type div p {
			font-size: 0.85rem;
		}
		.Introduction .blurb {
		    height: auto;
		}

		/* .MajorEvents {
			min-height: 125rem;
		} */

		/* .CorporateCulture {
			min-height: 49.75rem;
		} */
		.CorporateCulture-one.container-main,.CorporateCulture-two.container-main{
			padding: 0;
		}
		.CorporateCulture .CorporateCulture-one div {
		    width: 47.5%;
		    margin-right: 5%;
		}
		.CorporateCulture .CorporateCulture-one div:nth-child(2n){
			margin-right: 0;
		}	
		.CorporateCulture .CorporateCulture-two div {
		        width: 100%;
		        margin-right: 0;
		    }
		.EnterpriseHonor,.PublicCharity{
			    padding: 0.1rem 1.4375rem;
			
		}

		.EnterpriseHonor .EnterpriseHonor-list .type {
			width: 48%;
			margin-right: 4%;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type:nth-child(3n) {
			margin-right: 4%;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type:nth-child(2n) {
			margin-right: 0;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li .info-img {
			width: 100%;
			float: none;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li {
			height: auto;
			padding-top: 1.5rem;
			border-top: 1px solid #EEEFF0;
			margin-bottom: 1.5rem;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li .info-img {
			width: 100%;
			float: none;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li .info-c {
			width: 100%;
			padding: 1rem;
			float: none;
			height: 12.0625rem;
		}

		.PublicCharity .PublicCharity-list .PublicCharity-list-li .info-c .more img {
			float: left;
		}
		
	}

	@media (max-width: 430px) {
		/* .main .enteringHuifa-tab .tab-li {
			width: 16.6%;
			margin-right: 0%;
			padding: 0.625rem 0;
		} */

		.main .enteringHuifa-tab .tab-li p {
			margin-bottom: 0;
			font-size: 0.65rem;
		}

		.main .enteringHuifa-tab .tab-li p img {
			width: 1.625rem;
			height: 1.625rem;
		}

		/* .main .enteringHuifa-tab {
			height: 5.8rem;
		} */

		.Introduction .blurb .blurb-img {
			width: 100%;
		}

		.Introduction .blurb .blurb-info .title-E,
		.Introduction .target .target-title .title-E{
			margin-left: 7.5rem;
		}
		.Introduction .advantage .advantage-title .title-E{
			margin-left: 4.5rem;
		}

		.Introduction .blurb .blurb-info .title-C,
		.Introduction .target .target-title .title-C,
		.Introduction .advantage .advantage-title .title-C{
			margin-left: 5.65rem;
		}

		.Introduction .blurb .blurb-info .blurb-info-type {
			bottom: 3rem;
		}

		.Introduction .blurb {
			margin-bottom: 0;
		}

		.Introduction .advantage {
			height: auto;
			padding-top: 1.8125rem;
		}
		.Introduction .advantage .advantage-type h6 {
			margin-left: 13%;
		}

		.Introduction .advantage .advantage-info {
			width: 100%;
			float: none;
			margin-right: 0;
		}

		.Introduction .advantage .advantage-info:first-child {
			height: 5.375rem;
		}

		.Introduction .target .target-info .info-p {
			width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
		}
		

		.MajorEvents .timelineBox {
			position: absolute;
			left: 40%;
			top: 2%;
			width: 57%;
		}

		.MajorEvents .timelinItemBox-top div {
			width: 4.75rem;
			font-size: 1rem;
		}

		.MajorEvents .other .timelinItemBox-bottom p,
		.MajorEvents .timelinItemBox-bottom p {
			font-size: 0.75rem;
			line-height: 1.25rem;
		}

		/* .MajorEvents {
			min-height: 105rem;
		} */

		.CorporateCulture .CorporateCulture-one div {
			width: 47.5%;
			margin-right: 5%;
			margin-bottom: 1.5rem;
		}

		.CorporateCulture .CorporateCulture-one div:nth-child(2n) {
			margin-right: 0;
		}

		.CorporateCulture .CorporateCulture-two div {
			width: 100%;
			margin-right: 0;
			FLOAT: NONE;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type {
			width: 100%;
			margin-right: 0;
			height: 16.625rem;
		}

		.EnterpriseHonor .EnterpriseHonor-list .type .type-image {
			height: 13.625rem;
		}
	}
</style>