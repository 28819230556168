<template>
	<div class="main">
		<div class="Introduction">
			<div class="blurb container-secondary-main">
				<div class="blurb-img">
					<img :src="huifa_introduce.intro_img" alt="" style="width: 100%;">
				</div>
				<div class="blurb-info">
					<h4 class="title-E">COMPANY PROFILE</h4>
					<h4 class="title-C"><span class="title-C-g">{{lang.huifa_company}}</span><span>{{lang.huifa_company_profile}}</span></h4>
					<p class="info-c line-six" >{{stripHTMLTags(huifa_introduce.info)}}</p>
					<div class="blurb-info-type">
						<p><span>8</span>{{lang.huifa_eight_series}}</p>
						<p><span>6</span>{{lang.huifa_six_brand}}</p>
						<p><span>6</span>{{lang.huifa_six_service}}</p>
					</div>
				</div>
			</div>
			<div class="advantage">
				<div class="container-secondary-main">
					<div class="advantage-info advantage-title">
						<h4 class="title-E">COMPANY ADVANTAGES</h4>
						<h4 class="title-C"><span class="title-C-g">{{lang.huifa_company}}</span><span>{{lang.huifa_company_advantages}}</span></h4>
					</div>
					<div class="advantage-info advantage-type">
						<h6>{{huifa_introduce.series_title}}</h6>
						<div>
							<p class="line-five">{{huifa_introduce.series}}</p>
						</div>
					</div>
					<div class="advantage-info advantage-type">
						<h6>{{huifa_introduce.product_brand_title}}</h6>
						<div>
							<p class="line-five">{{huifa_introduce.product_brand}}</p>
						</div>
						
					</div>
					<div class="advantage-info advantage-type">
						<h6>{{huifa_introduce.service_brand_title}}</h6>
						<div>
							<p class="line-five">{{huifa_introduce.service_brand}}</p>
						</div>
						
					</div>
					<div class="advantage-info advantage-type">
						<h6>{{huifa_introduce.dish_title}}</h6>
						<div>
							<p class="line-five">{{huifa_introduce.dish}}</p>
						</div>
						
					</div>
					<div class="advantage-info advantage-type">
						<h6>{{huifa_introduce.industry_chain_title}}</h6>
						<div>
							<p class="line-five">{{huifa_introduce.industry_chain}} </p>
						</div>
						
					</div>
				</div>
			</div>
			<div class="target container-secondary-main">
				<div class="target-title">
					<h4 class="title-E">COMPANY  OBJECTIVES</h4>
					<h4 class="title-C"><span class="title-C-g">{{lang.huifa_company}}</span><span>{{lang.huifa_company_target}}</span></h4>
				</div>
				<div class="target-info">
					<div class="info-p">
						<p class="line-five">{{huifa_introduce.company_target_left}}</p>
					</div>
					<div class="info-p">
						<p class="line-five">{{huifa_introduce.company_target_right}}</p>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import { getHuifaIntroduce } from "@/api/index";
	import {
		mapState
	} from 'vuex'
	export default {
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				huifa_introduce:[],
				
			}
		},
		created() {
			this.getHuifaIntroduce()
		},
		methods:{
			getHuifaIntroduce(){
				getHuifaIntroduce().then(res=>{
					this.huifa_introduce = res.data.huifa_introduce
				})
			},
			stripHTMLTags(html) {
			  const doc = new DOMParser().parseFromString(html, 'text/html');
			  return doc.body.textContent || "";
			},
		}
	}
</script>

<style lang="less">
	.Introduction {
		.blurb {
			position: relative;
			margin: 6.0625rem auto 3.875rem;
			height: 30.5625rem;

			.blurb-img {
				width: 31.4375rem;

			}

			.blurb-info {
				width: 54.5rem;
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;

				.title-E {
					margin-left: 11.5rem;
				}

				.title-C {
					margin-left: 7.65rem;
					margin-top: 0.625rem;

					.title-C-g {
						background: #B60914;
						padding: 0.5rem 0.3125rem 0.625rem 3.9375rem;
						line-height: 1.5rem;
					}
				}

				.info-c {
					font-size: 0.875rem;
					color: #666;
					line-height: 1.875rem;
					width: 38.5rem;
					margin-left: 14rem;
					text-indent: 1.5rem;
					margin-top: 2.4375rem;
				}

				.blurb-info-type {
					position: absolute;
					width: 100%;
					bottom: 2rem;
					background: #FFFFFF;
					box-shadow: 0px 0px 18px 0px #cecece;
					height: 6.25rem;
					padding: 1rem 0;

					p {
						float: left;
						font-size: 0.875rem;
						width: 33.3%;
						border-right: 1px solid #F4F4F4;
						margin-bottom: 0;
						text-align: center;
					}

					span {
						color: #B60914;
						font-size: 3rem;
					}
				}
			}
		}

		.advantage {
			background-image: url('../../assets/enteringHuifa/Introduction_bj_1.png');
			background-size: 100% 100%;
			height: 38.125rem;
			padding-top: 7.8125rem;
			margin-bottom: 3.5625rem;

			.advantage-info {
				width: 20.25rem;
				height: 9.375rem;
				float: left;
				margin-right: 7.0625rem;
				margin-bottom: 3.125rem;
			}

			.advantage-title {
				.title-E {
					margin-left: 2.125rem;
					margin-top: 2rem;
				}

				.title-C {
					margin-top: 0.625rem;

					.title-C-g {
						background: #B60914;
						padding: 0.5rem 0.3125rem 0.625rem 3.9375rem;
						line-height: 1.5rem;
					}
				}
			}

			.advantage-type {
				background-image: url('../../assets/enteringHuifa/Introduction_bj_2.png');
				background-size: 100% 100%;

				h6 {
					font-weight: bold;
					font-size: 1.125rem;
					color: #333333;
					margin-left: 2.5rem;
					margin-top: -0.625rem;
					width: 6.5rem;
					text-align: center;
				}

				div {
					font-size: 0.875rem;
					color: #666666;
					line-height: 1.35rem;
					width: 87%;
					margin: 0 auto;
					text-align: justify;

					display: flex;
					align-items: center;
					/* 垂直居中 */
					height: 77%;
					/* 容器高度 */
					justify-content: center;
					/* 水平居中 */
				}
			}

			.advantage-info:nth-child(3n) {
				margin-right: 0;
			}
		}
		.target{
			padding-bottom: 10rem;
			.target-title{
				.title-E {
					margin-left: 45%;
				}
				
				.title-C {
					margin-top: 0.625rem;
					margin-left: 41%;
				
					.title-C-g {
						background: #B60914;
						padding: 0.5rem 0.3125rem 0.625rem 3.9375rem;
						line-height: 1.5rem;
					}
				}
			}
			.target-info{
				height: 13.25rem;
				margin-top: 2.75rem;
				.info-p{
					
					width: 49%;
					height: 100%;
					/* background: #FFFFFF;
					opacity: 0.5; */
					background: rgba(255, 255, 255, 0.5);
					float: left;
					margin-right: 2%;
					display: flex;
					align-items: center;
					/* 垂直居中 */
					height: 77%;
					/* 容器高度 */
					justify-content: center;
					padding: 1.25rem 3%;
					p{
						font-size: 0.875rem;
						color: #333333;
						line-height: 1.5rem;
						text-align: left;
					}
				}
				.info-p:nth-child(2n){
					margin-right: 0
				}
			}
		}
	}
</style>